

import { createSlice } from '@reduxjs/toolkit';

const predefinedColors = [
  'red', 'blue', 'green', 'yellow', 'black', 'white', 'brown', 'pink',
  'olive', 'purple', 'magenta', 'tan', 'orange', 'gray', 'mx' 
];

const initialState = {
  availableColors: predefinedColors,
  filterSelectedColors: [],
};

export const colorFilterSlice = createSlice({
  name: 'colorFilter',
  initialState,
  reducers: {
    setAvailableColors: (state, action) => {
      state.availableColors = action.payload;
    },
    addColor: (state, action) => {
      state.filterSelectedColors = [action.payload.toLowerCase()];
    },

  },
});

export const { setAvailableColors, addColor } = colorFilterSlice.actions;

export default colorFilterSlice.reducer;
