
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FENZACCI_BASE_URL from '../../config';

export const getBrandsImages = createAsyncThunk("brandsImages/getBrandsImages", async () => {
    try {
        const response = await fetch(`${FENZACCI_BASE_URL}/fenzacci/brands-images/`);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching brands images:', error);
        throw error;
    }
});


const getBrandsImageSlice = createSlice({
    name: 'brandsImages',
    initialState: {
        isLoading: false,
        data: [],
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(getBrandsImages.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getBrandsImages.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(getBrandsImages.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
        });
    }
});

export default getBrandsImageSlice.reducer;

