import { createSlice } from '@reduxjs/toolkit';
import { createOrder, postUserAddress, completeCheckout, getUserCompleteOrders } from './orderActions'; 

const initialState = {
  cart: {},
  address: {},
  checkout: {},
  orders: {}, 
  loading: false, 
  error: null, 
};

const orderSlice = createSlice({
  name: 'customerOrder',
  initialState,
  reducers: {
    updateCart(state, action) {
      state.cart = action.payload;
    },
    updateAddress(state, action) {
      state.address = action.payload;
    },
    updateCheckout(state, action) {
      state.checkout = action.payload;
    },
    checkoutOrder(state, action) {
      state.orders = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrder.fulfilled, (state, action) => {
        state.cart = action.payload;
      })
      .addCase(postUserAddress.fulfilled, (state, action) => {
        state.address = action.payload;
      })
      .addCase(completeCheckout.fulfilled, (state, action) => {
        state.checkout = action.payload;
      })
      .addCase(getUserCompleteOrders.fulfilled, (state, action) => {
        state.orders = action.payload; 
        state.loading = false; 
      })
      .addCase(getUserCompleteOrders.pending, (state) => {
        state.loading = true; 
      })

      .addCase(getUserCompleteOrders.rejected, (state, action) => {
        state.loading = false; 
        state.error = action.error.message; 
      });
  },
});

export const { updateCart, updateAddress, updateCheckout,checkoutOrder } = orderSlice.actions;
export default orderSlice.reducer;
