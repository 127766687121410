import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query';
import { HelmetProvider } from 'react-helmet-async';
import Loader from "./components/loader/Loader";

import GoogleLoginButtonWrapper from "./router/GoogleLoginButtonWrapper";

const FenzacciFooter = lazy(() => import("./components/footer/FenzacciFooter"));
const TermsConditions = lazy(() => import("./components/termsConditions/TermsConditions"));
const MainContainer = lazy(() => import("./components/mainContainer/MainContainer"));
const ProductListPage = lazy(() => import("./components/productlist/ProductListPage"));
const DetailPage = lazy(() => import("./components/detailPage/DetailPage"));
const CartPage = lazy(() => import("./components/cartPage/CartPage"));
const OrderAddress = lazy(() => import("./components/addressPage/OrderAddress"));
const Checkout = lazy(() => import("./components/checkoutpage/Checkout"));
const OrderCompletePage = lazy(() => import("./components/orderComplete/OrderCompletePage"));
const UserOTPLogin = lazy(() => import("./components/loginCrediential/UserOTPLogin"));
const EnterOTP = lazy(() => import("./components/loginCrediential/EnterOTP"));
const Login = lazy(() => import("./components/loginCrediential/Login"));
const FenzacciAdmin = lazy(() => import("./components/FenzacciAdmin/mainAdminPage/FenzacciAdmin"));
const OrderDetailPage = lazy(() => import("./components/FenzacciAdmin/orderDetailPage/OrderDetailPage"));
const HomeCategories = lazy(() => import("./components/customSlider/HomeCategories"));
const HomePage = lazy(() => import('./components/homepages/HomePage'));
const FenzacciHeader = lazy(() => import('./components/header/topHeader/FenzacciHeader'));
const FenzacciHeaderMenubar = lazy(() => import('./components/header/submenuHeader/FenzacciHeaderMenubar'));
const HomeTopSlider = lazy(() => import('./components/hometopbanner/HomeTopSlider'));
const BrandsMarquee = lazy(() => import('./components/brandsImages/BrandsMarquee'));


const queryClient = new QueryClient();
const MainLayout = ({ children }) => {
  return (
    <div>
      <Suspense fallback={<Loader />}>
        <FenzacciHeader />
        <FenzacciHeaderMenubar />
        <main>{children}</main>
      </Suspense>
    </div>
  );
};

const AdminLayout = ({ children }) => {
  return <div>{children}</div>;
};

const AppWithRouter = () => {
  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={
                <MainLayout>
                  <MainContainer>
                    <HomeTopSlider />
                  </MainContainer>

                  <MainContainer>
                    <HomeCategories />
                  </MainContainer>

                  <MainContainer>
                    <BrandsMarquee />
                  </MainContainer>

                  <MainContainer>
                    <HomePage />
                  </MainContainer>

                  <FenzacciFooter />
                  <GoogleLoginButtonWrapper />
                </MainLayout>
              } />
              <Route path="categories/:slug" element={<MainLayout><ProductListPage type="categories" /></MainLayout>} />
              <Route path="subcategories/:slug" element={<MainLayout><ProductListPage type="subcategories" /></MainLayout>} />
              <Route path="subsubcategories/:slug" element={<MainLayout><ProductListPage type="subsubcategories" /></MainLayout>} />
              <Route path="/global-search/:searchQuery" element={<MainLayout><ProductListPage type="globalSearch" /></MainLayout>} />
              <Route path="/detailPage/:id/:slug" element={<MainLayout><DetailPage type="detailPage" /></MainLayout>} />
              <Route path="/terms-conditions/:slug" element={<MainLayout><TermsConditions type="termsConditions" /></MainLayout>} />
              <Route path="/cart" element={<MainLayout><CartPage /></MainLayout>} />
              <Route path="/orderAddress/" element={<MainLayout><OrderAddress /></MainLayout>} />
              <Route path="/checkout/" element={<MainLayout><Checkout /></MainLayout>} />
              <Route path="/order-complete/" element={<MainLayout><OrderCompletePage /></MainLayout>} />
              <Route path="/user-login/" element={<MainLayout><UserOTPLogin /></MainLayout>} />
              <Route path="/otp-confirmation" element={<MainLayout><EnterOTP /></MainLayout>} />
              <Route path="/fenzacci-user-login" element={<MainLayout><Login /></MainLayout>} />
              <Route path="/fenzacci-admin" element={<AdminLayout><FenzacciAdmin /></AdminLayout>} />
              <Route path="/retrieve/user/order/:orderNumber/" element={<AdminLayout><OrderDetailPage /></AdminLayout>} />

            </Routes>
          </Suspense>
        </HelmetProvider>
      </QueryClientProvider>
    </Router>
  );
};

export default AppWithRouter;
