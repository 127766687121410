
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FENZACCI_BASE_URL from '../../config';

export const getShippingAddress = createAsyncThunk("shippindAddress", async () => {
    try {
        const response = await fetch(`${FENZACCI_BASE_URL}/cart/shipping-address/`);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching Shipping address:', error);
        throw error;
    }
});


const getShippingAddresSlice = createSlice({
    name: 'GetShippingAddress',
    initialState: {
        isLoading: false,
        data: [],
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(getShippingAddress.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getShippingAddress.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(getShippingAddress.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
        });
    }
});

export default getShippingAddresSlice.reducer;

