// otpVerificationSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import FENZACCI_BASE_URL from '../../config';

export const verifyOTP = createAsyncThunk(
  'otpVerification/verifyOTP',
  async ({ email, otpCode }, { rejectWithValue }) => {
    try {
      const response = await fetch(`${FENZACCI_BASE_URL}/otp-verifications/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, otp: otpCode }),
      });
      const data = await response.json();
      if (!response.ok) {
        return rejectWithValue(data.detail);
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
  

const otpVerificationSlice = createSlice({
  name: 'otpVerification',
  initialState: {
    isVerified: false,
    loading: false,
    error: null,
    data: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyOTP.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(verifyOTP.fulfilled, (state, action) => {
        state.isVerified = true;
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(verifyOTP.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default otpVerificationSlice.reducer;
