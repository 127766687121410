import { createSlice } from '@reduxjs/toolkit';

const predefinedSizes = [
    'XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL', 'O/S'
  ];
  

const initialSizeState = {
  availableSizes: predefinedSizes,
  filterSelectedSizes: [],
};

export const sizeFilterSlice = createSlice({
  name: 'sizeFilter',
  initialState: initialSizeState,
  reducers: {
    setAvailableSizes: (state, action) => {
      state.availableSizes = action.payload;
    },
    addSize: (state, action) => {
      state.filterSelectedSizes = [action.payload.toUpperCase()];
    },

  },
});

export const { setAvailableSizes, addSize} = sizeFilterSlice.actions;

export default sizeFilterSlice.reducer;
