import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FENZACCI_BASE_URL from '../../config';

export const createTabbySession = createAsyncThunk("tabby/createSession", async (paymentData, { rejectWithValue }) => {
    try {
        const response = await fetch(`${FENZACCI_BASE_URL}/cart/api/v1/tabby-payment/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(paymentData)
        });

        if (!response.ok) {
            const errorData = await response.json();
            return rejectWithValue(errorData.error);
        }
        const data = await response.json();
        if (data.tabby_payment_url) { 
            window.location.href = data.tabby_payment_url;
          }
        return data;
    } catch (error) {
        console.error('Error creating Tabby session:', error);
        return rejectWithValue(error.toString());
    }
});

const tabbyPaymentSlice = createSlice({
    name: 'tabbyPayment',
    initialState: {
        isLoading: false,
        data: [],
        error: null
    },
    extraReducers: (builder) => {
        builder
            .addCase(createTabbySession.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createTabbySession.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
                if (typeof action.payload === 'string') {
                    window.location.assign(action.payload);
                }
            })
            .addCase(createTabbySession.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            });
    }
});

export default tabbyPaymentSlice.reducer;
