

import {createAsyncThunk } from '@reduxjs/toolkit';
import FENZACCI_BASE_URL from '../../config';

export const getUserOrdersList = createAsyncThunk(
    'order/getUserOrdersList',
    async () => {
      const accessToken = JSON.parse(localStorage.getItem('sup_access_token'));
      const response = await fetch(`${FENZACCI_BASE_URL}/cart/api/v1/user/order/list/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || response.statusText);
      }
  
      const adminOrdersData = await response.json();
      return adminOrdersData;
    }
  );
  