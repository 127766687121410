

import {createAsyncThunk } from '@reduxjs/toolkit';
import FENZACCI_BASE_URL from '../../config';

import { getTokens } from '../../services/tokenService';


export const createOrder = createAsyncThunk(
  'order/createOrder',
  async ({ data, token }) => {
    data.cart_items.forEach((item) => {
      item.cart_products.forEach((product) => {
        if (Array.isArray(product.color) && product.color.length === 0) {
          product.color = null;
        }
      });
    });
    
    const response = await fetch(`${FENZACCI_BASE_URL}/cart/api/v1/cart/orders/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    
    const responseData = await response.json();
    return responseData;
  }
);



export const postUserAddress = createAsyncThunk(
  'order/postUserAddress',
  async (data) => {
    try {
      const { accessToken, googleToken } = getTokens();
    const token = accessToken || googleToken;

      const response = await fetch(`${FENZACCI_BASE_URL}/cart/api/v1/address/orders/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || response.statusText);
      }
      const cartPostData = await response.json();
      return cartPostData;
    } catch (error) {
      console.error('Error posting user address:', error);
      throw error;
    }
  }
);

export const completeCheckout = createAsyncThunk(
  'order/completeCheckout',
  async (data) => {
    const { accessToken, googleToken } = getTokens();
    const token = accessToken || googleToken;
    const response = await fetch(`${FENZACCI_BASE_URL}/cart/api/v1/complete/orders/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }
    const checkoutOrderData = await response.json();
    return checkoutOrderData;
  }
);


export const getUserCompleteOrders = createAsyncThunk(
  'order/getUserCompleteOrders',
  async () => {
    const { accessToken, googleToken } = getTokens();
    const token = accessToken || googleToken;
    const response = await fetch(`${FENZACCI_BASE_URL}/cart/api/v1/user/complete/order/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || response.statusText);
    }

    const ordersData = await response.json();
    return ordersData;
  }
);
