// EmailOTPLogin.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import FENZACCI_BASE_URL from '../../config';
export const emailOTPLogin = createAsyncThunk(
  'ByEmailOTP/emailOTPLogin',
  async (email, { rejectWithValue }) => {
    try {
      const response = await fetch(`${FENZACCI_BASE_URL}/user-otp-registrations/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData.detail);
      }
      return await response.json();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const emailOTPSlice = createSlice({
  name: 'ByEmailOTP',
  initialState: {
    email: null,
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(emailOTPLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(emailOTPLogin.fulfilled, (state, action) => {
        state.email = action.payload;
        state.loading = false;
      })
      .addCase(emailOTPLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default emailOTPSlice.reducer;
