import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import FENZACCI_BASE_URL from '../../config';

export const getMainCategory = createAsyncThunk(
  'mainCategory',
  async (slug) => {
    const response = await fetch(`${FENZACCI_BASE_URL}/fenzacciCategories/${slug}/`);
    const data = await response.json();
    return data;
  }
);

const mainCategorySlice = createSlice({
  name: 'categoryDetail',
  initialState: {
    isLoading: false,
    data: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getMainCategory.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMainCategory.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(getMainCategory.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message || 'An error occurred';
    });
  },
});

export default mainCategorySlice.reducer;
