import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FENZACCI_BASE_URL from '../../config';

// Define the async thunk for getting user ratings for a specific product
export const getUserRatings = createAsyncThunk(
  'userRatings/getUserRatings',
  async (productId, { rejectWithValue }) => {
    try {
      const response = await fetch(`${FENZACCI_BASE_URL}/store/comments/?productId=${productId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        const error = await response.json();
        return rejectWithValue(error);
      }

      const data = await response.json();

      // Ensure the data is an array before returning
      if (!Array.isArray(data)) {
        throw new Error('Expected an array but got a different type');
      }

      return data;
    } catch (error) {
      console.error('Error:', error);
      return rejectWithValue(error.message);
    }
  }
);

const getRatingSlice = createSlice({
  name: 'userRatings',
  initialState: {
    userRatings: [],
    averageRating: 0,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserRatings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getUserRatings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userRatings = action.payload;
        state.averageRating = action.payload.length
          ? action.payload.reduce((acc, { rating }) => acc + rating, 0) / action.payload.length
          : 0;
      })
      .addCase(getUserRatings.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || 'Failed to load user ratings';
      });
  },
});

export default getRatingSlice.reducer;
