import GoogleLoginButton from '../components/loginCrediential/GoogleButton';
import { useLocation, useNavigate } from 'react-router-dom';

function GoogleLoginButtonWrapper() {
  const location = useLocation();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);
  const email = urlParams.get('email');
  const name = urlParams.get('name');
  const userId = urlParams.get('user_id');
  const accessToken = urlParams.get('access_token');

  if (email && name && userId && accessToken) {
    const googleLoginAuthData = { email, name, userId, accessToken };
    localStorage.setItem('googleLoginAuthData', JSON.stringify(googleLoginAuthData));
    navigate('/', { replace: true });
  }

  const storedData = localStorage.getItem('googleLoginAuthData');
  if (storedData) {
    return null; 
  }

  return (
    <>
      {location.pathname !== '/' && <GoogleLoginButton />} 
    </>
  );
}

export default GoogleLoginButtonWrapper;