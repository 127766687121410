import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FENZACCI_BASE_URL from '../../config';

export const fetchDetailPage = createAsyncThunk("fetchDetailPage", async ({id, slug}) => {
    const url = `${FENZACCI_BASE_URL}/products/${id}/${slug}/`;
    const data = await fetch(url);
    return data.json()
})

const fetchDetailSlice = createSlice({
    name: 'DetailPage',
    initialState: {
        isLoading: false,
        data: null,
        error: false,
        product: null, 
    },
    reducers: {
      setProduct: (state, action) => {
        state.product = action.payload;
      },
      reset: (state) => {
        state.isLoading = false;
        state.data = null;
        state.error = false;
        state.product = null;
      },
  },
    extraReducers: (builder) => {
        builder.addCase(fetchDetailPage.pending, (state, action) => {
            state.isLoading = true
        });
        builder.addCase(fetchDetailPage.fulfilled, (state, action) => {
            state.isLoading = false;
            state.product = action.payload;
        });
        builder.addCase(fetchDetailPage.rejected, (state, action) => {
            state.error = true;
        })
    }
})

export const {product,setProduct, reset} = fetchDetailSlice.actions;

export default fetchDetailSlice.reducer