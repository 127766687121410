
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import FENZACCI_BASE_URL from '../../config';

export const getMenuCategories = createAsyncThunk('menuCategory/getMenuCategories', async (slug, { rejectWithValue }) => {
  try {
    const url = slug ? `${FENZACCI_BASE_URL}/mega-categories/${slug}/` : `${FENZACCI_BASE_URL}/mega-categories/`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Server Error');
    }
    return await response.json();
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const menuCategorySlice = createSlice({
    name: 'menuCategory',
    initialState: {
        isLoading: false,
        data: null,
        error: false,
        mainCategory: [],
        subCategories:[],
        subSubCategories:[],

    },
    reducers: {
        setMainCategory: (state, action) => {
          state.mainCategory = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getMenuCategories.pending, (state, action) => {
            state.isLoading = true
        });

        
        builder.addCase(getMenuCategories.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload
        });
        builder.addCase(getMenuCategories.rejected, (state, action) => {
            state.error = true;
            state.isLoading = false;
        })
    }
})


export default menuCategorySlice.reducer