
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import FENZACCI_BASE_URL from '../../config';

export const creditCardPayment = createAsyncThunk(
  "cardPayment",
  async (paymentData, { rejectWithValue }) => {
    try {
      const response = await fetch(`${FENZACCI_BASE_URL}/cart/api/v1/network-payment/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(paymentData),
      });
      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData.error);
    }
    const data = await response.json();
    if (data.payment_url) { 
      window.location.href = data.payment_url;
    }
    return data;

    } catch (error) {
      console.error('Error processing payment:', error);
      return rejectWithValue(error.toString());
    }
  },
);
  
const cardPaymentSlice = createSlice({
    name: 'cardPayment',
    initialState: {
        isLoading: false,
        data: [],
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(creditCardPayment.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(creditCardPayment.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(creditCardPayment.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
        });
    }
});

export default cardPaymentSlice.reducer;
