import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FENZACCI_BASE_URL from '../../config';

export const postUserRatings = createAsyncThunk(
  'userRatings/postUserRatings',
  async ({ comment, rating, productId }) => {
    try {
      const response = await fetch(`${FENZACCI_BASE_URL}/store/comments/create/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ comment, rating, productId }),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error:', error);
    }
  }
);

const postRatingSlice = createSlice({
  name: 'userRatings',
  initialState: {
    userRatings: [],
  },
  extraReducers: (builder) => {
    builder.addCase(postUserRatings.fulfilled, (state, action) => {
      state.userRatings.push(action.payload);
    });
  },
});

export default postRatingSlice.reducer;
