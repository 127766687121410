import Styles from './socialauth.module.css';
import { FcGoogle } from 'react-icons/fc';
import { IoLogoFacebook } from 'react-icons/io5';
import FENZACCI_BASE_URL from '../../config';

function GoogleLoginButton() {
  const handleLogin = () => {
    window.location.href = `${FENZACCI_BASE_URL}/api/google-auth/`;
  };

  return (
    <div className={Styles.loginByGoogle}>
      <div className={Styles.continueTitle}>
        <h4>Or continue with other ways</h4>
      </div>
      <div className={Styles.socialIcons}>
        <ul>
          <li>
            <button onClick={handleLogin}>
              <FcGoogle />
            </button>
          </li>
          {/* <li>
            <button>
              <IoLogoFacebook style={{ color: '#1e6fb7' }} />
            </button>
          </li> */}
        </ul>
      </div>
    </div>
  );
}

export default GoogleLoginButton;