

import { createSlice } from "@reduxjs/toolkit";
import { getUserOrdersList } from "./adminOrderActions";

const initialState = {
    adminOrderList: [],
    loading: false, 
    error: null, 
}

const adminOrderListSlice = createSlice({
    name:'adminOrders',
    initialState,

    reducers:{
        adminOrder(state,action){
        state.adminOrderList = action.payload
        }
    },

    extraReducers:(builder) =>{
        builder
        .addCase(getUserOrdersList.fulfilled,(state,action) =>{
            state.adminOrderList=action.payload;
            state.loading = false;
        })

        .addCase(getUserOrdersList.pending,(state) =>{
            state.loading= true
        })
        .addCase(getUserOrdersList.rejected,(state,action)=>{
            state.loading = false;
            state.error = action.error.message;
        })
    }   

})


export const {adminOrder} = adminOrderListSlice.actions;
export default adminOrderListSlice.reducer;