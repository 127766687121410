
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import FENZACCI_BASE_URL from '../../config';

export const getDefaultCategories = createAsyncThunk("defaultCategory", async () => {
    const data = await fetch(`${FENZACCI_BASE_URL}/main-categories/`)
    return data.json()
})

const defaultCategorySlice = createSlice({
    name: 'defaultCategory',
    initialState: {
        isLoading: false,
        data: null,
        error: false
    },

    extraReducers: (builder) => {
        builder.addCase(getDefaultCategories.pending, (state, action) => {
            state.isLoading = true
        });


        builder.addCase(getDefaultCategories.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload
        });
        builder.addCase(getDefaultCategories.rejected, (state, action) => {
            state.error = true;
            state.isLoading = false;
        })
    }
})

export default defaultCategorySlice.reducer