
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import FENZACCI_BASE_URL from '../../config';

export const fetchSocialMedia= createAsyncThunk("fetchTodo", async () => {
    const data = await fetch(`${FENZACCI_BASE_URL}/fenzacci/social-media-links/`);
    return data.json()
})

const socailMediaSlice = createSlice({
    name: 'socialMedia',
    initialState: {
        isLoading: false,
        data: null,
        error: false
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSocialMedia.pending, (state, action) => {
            state.isLoading = true
        });
        builder.addCase(fetchSocialMedia.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload
        });
        builder.addCase(fetchSocialMedia.rejected, (state, action) => {
            state.error = true;
            state.isLoading = false;
        })
    }
})

export default socailMediaSlice.reducer