import { createSlice } from '@reduxjs/toolkit';

const productVaritionSlice = createSlice({
  name: 'ProductOptions',
  initialState: {
    quant: 1,
    selectedSize: [],
    selectedColor: [],
    selectedImage: null,
    cart: JSON.parse(localStorage.getItem('cart')) || [],
    subTotal: 0,
    wasPriceTotal: 0,
    totalDiscount: 0,
    totalItems: 0,
    vatTax: 0,
    grantTotal: 0,
  },
  reducers: {
    setSelectedSize: (state, action) => {
      state.selectedSize = action.payload;
    },
    setSelectedColor: (state, action) => {
      state.selectedColor = action.payload;
    },
    setSelectedImage: (state, action) => {
      state.selectedImage = action.payload;
    },
    incrementQuantity: (state) => {
      state.quant += 1;
    },
    decrementQuantity: (state) => {
      if (state.quant > 1) {
        state.quant -= 1;
      }
    },
    resetQuantity: (state) => {
      state.quant = 1;
    },
    addToCart: (state, action) => {
      const uniqueId = `${action.payload.id}_${action.payload.size}_${action.payload.color}`;
      const existingItem = state.cart.find((item) => item.uniqueId === uniqueId);

      if (existingItem) {
        existingItem.quant += action.payload.quant;
      } else {
        state.cart.push({ ...action.payload, uniqueId });
      }

      localStorage.setItem('cart', JSON.stringify(state.cart));
      productVaritionSlice.caseReducers.cartItemCalculations(state);
    },
    cartItemCalculations: (state) => {
      const subTotal = state.cart.reduce((total, item) => total + parseFloat(item.productprice) * item.quant, 0);
      const wasPriceTotal = state.cart.reduce((total, item) => total + parseFloat(item.wasPrice) * item.quant, 0);
      const totalDiscount = state.cart.reduce((total, item) => total + parseFloat(item.totalSavingAmount) * item.quant, 0);
      const totalItems = state.cart.reduce((total, item) => total + item.quant, 0);
      const vatTax = subTotal * 0.05;

      state.subTotal = subTotal.toFixed(2);
      state.wasPriceTotal = wasPriceTotal.toFixed(2);
      state.totalDiscount = totalDiscount.toFixed(2);
      state.totalItems = totalItems;
      state.vatTax = vatTax.toFixed(2);
      state.grantTotal = (subTotal + vatTax).toFixed(2);

      localStorage.setItem('cart', JSON.stringify(state.cart));
    },
    removeItem: (state, action) => {
      state.cart = state.cart.filter((item) => item.uniqueId !== `${action.payload.id}_${action.payload.size}_${action.payload.color}`);
      productVaritionSlice.caseReducers.cartItemCalculations(state);
    },
    increaseItemQuantity: (state, action) => {
      state.cart = state.cart.map((item) => {
        if (item.uniqueId === action.payload.uniqueId) {
          return { ...item, quant: item.quant + 1 };
        }
        return item;
      });
      productVaritionSlice.caseReducers.cartItemCalculations(state);
    },
    
    decreaseItemQuantity: (state, action) => {
      state.cart = state.cart.map((item) => {
        if (item.uniqueId === action.payload.uniqueId) {
          return { ...item, quant: Math.max(1, item.quant - 1) };
        }
        return item;
      });
      productVaritionSlice.caseReducers.cartItemCalculations(state);
    },
  },
});

export const {
  setSelectedSize,
  setSelectedColor,
  setSelectedImage,
  incrementQuantity,
  decrementQuantity,
  addToCart,
  removeItem,
  increaseItemQuantity,
  decreaseItemQuantity,
  cartItemCalculations,
  resetQuantity
} = productVaritionSlice.actions;

export default productVaritionSlice.reducer;