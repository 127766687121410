// categoriesSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FENZACCI_BASE_URL from '../../config';

// Thunk to fetch categories
export const fetchHomePageCategories = createAsyncThunk('categories/fetchCategories', async (_, { rejectWithValue }) => {
  try {
    const responses = await Promise.all([
      fetch(`${FENZACCI_BASE_URL}/fenzacciCategories/women/`),
      fetch(`${FENZACCI_BASE_URL}/fenzacciCategories/men/`),
      fetch(`${FENZACCI_BASE_URL}/fenzacciCategories/kids/`),
    ]);

    if (!responses.every(response => response.ok)) {
      throw new Error('Error fetching categories');
    }

    const data = await Promise.all(responses.map(response => response.json()));

    return {
      women: data[0]?.subcategories || [],
      men: data[1]?.subcategories || [],
      kids: data[2]?.subcategories || [],
    };
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const mainPageCategoriesSlice = createSlice({
  name: 'HomePageCateg',
  initialState: {
    women: [],
    men: [],
    kids: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHomePageCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchHomePageCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.women = action.payload.women;
        state.men = action.payload.men;
        state.kids = action.payload.kids;
      })
      .addCase(fetchHomePageCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export default mainPageCategoriesSlice.reducer;
