import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import FENZACCI_BASE_URL from '../../config';

export const getSubSubCategories = createAsyncThunk(
    "subSubCategory",
    async (slug) => {
      const response = await fetch(`${FENZACCI_BASE_URL}/fenzacciSubsubcategories/${slug}/`);
      const subCategoryData = await response.json();
      return subCategoryData;
    }
  );
  
  const subSubCategorySlice = createSlice({
    name: "subSubCategoryDetail",
    initialState: {
      isLoading: false,
      data: null,
      error: false,
    },
    extraReducers: (builder) => {
      builder.addCase(getSubSubCategories.pending, (state) => {
        state.isLoading = true;
      });
      builder.addCase(getSubSubCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      });
      builder.addCase(getSubSubCategories.rejected, (state) => {
        state.error = true;
        state.isLoading = false;
      });
    },
  });
  
  export default subSubCategorySlice.reducer;
  