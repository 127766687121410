


export const getTokens = () => {
    const tokens = {
      accessToken: null,
      googleToken: null,
    };
    const storedToken = JSON.parse(localStorage.getItem('accessToken'));
    if (storedToken) {
      tokens.accessToken = storedToken;
    }
    const googleLoginSessionData = localStorage.getItem('googleLoginAuthData');
    if (googleLoginSessionData) {
      const parsedGoogleData = JSON.parse(googleLoginSessionData);
      tokens.googleToken = parsedGoogleData.accessToken;
    }
    return tokens; 
  };
  export const clearTokens = () => {
    localStorage.removeItem('accessToken');
    sessionStorage.removeItem('googleLoginAuthData');
  };
  