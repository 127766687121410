import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FENZACCI_BASE_URL from '../../config';

export const getSearchProducts = createAsyncThunk(
    "SearchProducts",
    async (searchQuery) => {
      const response = await fetch(
        `${FENZACCI_BASE_URL}/store/global-search/?search=${searchQuery}`
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    }
  );

const globalSearchSlice = createSlice({
    name: 'FenzacciSearch',
    initialState: {
        isLoading: false,
        data: [],
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(getSearchProducts.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(getSearchProducts.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(getSearchProducts.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
        });
    }
});

export default globalSearchSlice.reducer;
