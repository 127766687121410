
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FENZACCI_BASE_URL from '../../config';

export const getHomePage= createAsyncThunk("homePages", async () => {
    const homePagedata = await fetch(`${FENZACCI_BASE_URL}/home-page-items/`);
    return homePagedata.json()
})

const homePagesSlice = createSlice({
    name: 'homePages',
    initialState: {
        isLoading: false,
        homePagedata: [],
        error: false
    },
    
    extraReducers: (builder) => {
        builder.addCase(getHomePage.pending, (state) => {
            state.isLoading = true;
        });
        
        builder.addCase(getHomePage.fulfilled, (state, action) => {
            const filteredData = action.payload.filter(item => item.item_type === "category");
            state.isLoading = false;
            state.homePagedata = filteredData;
            localStorage.setItem('homePageItems', JSON.stringify(filteredData));
        });

        builder.addCase(getHomePage.rejected, (state, action) => {
            state.error = true;
            state.isLoading = false;
        });
    }
});

export default homePagesSlice.reducer;

