
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import FENZACCI_BASE_URL from '../../config';

export const getSubCategories = createAsyncThunk(
    "subCategory",
    async (slug) => {
      const response = await fetch(`${FENZACCI_BASE_URL}/fenzacciSubcategories/${slug}/`);
      const subCategoryData = await response.json();
      return subCategoryData;
    }
  );
  
  const subCategorySlice = createSlice({
    name: "subCategoryDetail",
    initialState: {
      isLoading: false,
      data: null,
      error: false,
    },
    extraReducers: (builder) => {
      builder.addCase(getSubCategories.pending, (state) => {
        state.isLoading = true;
      });
      builder.addCase(getSubCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      });
      builder.addCase(getSubCategories.rejected, (state) => {
        state.error = true;
        state.isLoading = false;
      });
    },
  });
  
  export default subCategorySlice.reducer;
  